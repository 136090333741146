import { Email, LocationOn, Phone } from "@mui/icons-material"
import { Box, Button, Grid, Typography, useTheme } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

import { ReactComponent as LogoSvg } from "@/assets/images/logo.svg"
import { NavigationItem } from "@/routes"

export const Footer = (props: { navigation: NavigationItem[] }) => {
  const theme = useTheme()
  const { navigation } = props

  return (
    <footer>
      <Grid container bgcolor={theme.palette.primary.dark} color="#fff">
        <Grid container p={10} maxWidth={1280} margin="auto">
          <Grid container rowSpacing={10} sx={{ opacity: 0.7 }}>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item mx={{ xs: "auto", md: 0 }}>
                  <Link to="/">
                    <Grid container alignItems="center" flexWrap="nowrap">
                      <LogoSvg
                        height={90}
                        width={90}
                        style={{ filter: "invert(1)" }}
                      />
                      <Box
                        ml={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography
                          fontWeight={500}
                          fontSize="2.5rem"
                          letterSpacing=".3rem"
                          lineHeight="1.2rem"
                          mr="-.3rem"
                        >
                          MBN
                        </Typography>
                        <Typography fontSize="1.1rem" lineHeight="1rem" mt={1}>
                          contractors
                        </Typography>
                      </Box>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Grid container flexDirection="column" rowGap={2}>
                {navigation.map(nav => (
                  <Grid item key={nav.name}>
                    <Link to={nav.path}>
                      <Button
                        color="inherit"
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: 300,
                          lineHeight: 1.5,
                        }}
                      >
                        {nav.name}
                      </Button>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Grid container flexDirection="column" rowGap={2}>
                <Grid container alignItems="center" flexWrap="nowrap">
                  <Box mr={1.5}>
                    <LocationOn />
                  </Box>
                  <Box>
                    <Typography>
                      9154 Centerway Rd,
                      <br />
                      Gaithersburg, MD 20879
                    </Typography>
                  </Box>
                </Grid>
                <Grid container alignItems="center" flexWrap="nowrap">
                  <Box mr={1.5}>
                    <Phone />
                  </Box>
                  <Box>
                    <Link to="tel:+1 (301) 996-3362">+1 (301) 996-3362</Link>
                  </Box>
                </Grid>
                <Grid container alignItems="center" flexWrap="nowrap">
                  <Box mr={1.5}>
                    <Email />
                  </Box>
                  <Box>
                    <Link to="mailto:info@mbncontractors.com">
                      info@mbncontractors.com
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          item
          width="100%"
          textAlign="center"
          bgcolor={theme.palette.primary.dark}
          p={5}
        >
          <Typography fontSize="small">
            Copyright © 2023 MBN Contractors, LLC. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </footer>
  )
}
