import {
  AppBar,
  Container,
  Toolbar,
  Theme,
  alpha,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material"
import React from "react"

import { MobileMenu, DesktopMenu } from "@/components/Navbar/menus"
import { NavigationItem } from "@/routes"

export const Navbar = (props: {
  threshold: number
  navigation: NavigationItem[]
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const { threshold } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: threshold,
    // target: window ? window() : undefined,
  })

  const style = {
    transition: "all .2s ease-out",
    background: `linear-gradient(${alpha(
      theme.palette.primary.dark,
      0.5,
    )}, transparent)`,
    color: "#FFF",
    invert: 1,
  }

  if (trigger) {
    style.background = "#FFF"
    style.color = theme.palette.primary.dark
    style.invert = 0
  }

  return (
    <AppBar elevation={0} style={style}>
      <Container maxWidth={false} sx={{ px: { xs: 2, sm: 5, md: 10 }, py: 1 }}>
        <Toolbar disableGutters>
          {isMobile ? (
            <MobileMenu style={style} {...props} />
          ) : (
            <DesktopMenu style={style} {...props} />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
