import { Box, Grid, Typography, useTheme } from "@mui/material"
import React from "react"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

import { ContentLayout } from "@/components/Layout/ContentLayout"
import { getUrl } from "@/utils/cloudinary"

export const services = [
  {
    name: "kitchens",
    id: "pv9x6i",
    description:
      "Our remodeling company specializes in transforming kitchens into " +
      "stunning and functional spaces.",
  },
  {
    name: "bathrooms",
    id: "f2j0rh",
    description:
      "We transform bathrooms into serene retreats, ensuring " +
      "a seamless blend of functionality and elegance.",
  },
  {
    name: "interiors",
    id: "yemw1u",
    description:
      "We transform interiors into inviting and inspiring environments " +
      "that reflect your unique taste and lifestyle.",
  },
  {
    name: "exteriors",
    id: "sw9uff",
    description:
      "From updating siding and windows to refreshing entryways " +
      "and landscaping, we take pride in creating stunning exteriors.",
  },
  {
    name: "basements",
    id: "gi11az",
    description:
      "Transform your basement into a functional living space to creating a " +
      "home theater or a stylish entertainment area and maximize its potential.",
  },
  {
    name: "sheds",
    id: "xa2iwy",
    description:
      "We transform sheds into versatile and aesthetically pleasing " +
      "structures for your outdoor area.",
  },
] as { name: string; id: string; description: string }[]

export const Services = () => {
  const theme = useTheme()

  return (
    <ContentLayout
      title="Services | MBN Contractors"
      description="Services that we provide"
    >
      <Grid position="relative" bgcolor={theme.palette.primary.dark}>
        <ParallaxBanner style={{ height: "30vh", opacity: 0.65 }}>
          <ParallaxBannerLayer
            image={getUrl("sipzza")}
            expanded={false}
            speed={-50}
            startScroll={-1000}
            endScroll={1000}
            style={{ left: -80, backgroundPosition: "bottom" }}
          />
        </ParallaxBanner>
      </Grid>
      <Grid container bgcolor={theme.palette.secondary.main}>
        <Grid maxWidth={1280} margin="auto">
          <Grid
            container
            rowGap={10}
            columnSpacing={10}
            textAlign="center"
            sx={{ px: { xs: 5, sm: 10 }, py: 10 }}
          >
            <Grid item xs={12} textAlign="center" data-aos="fade-up">
              <Box>
                <Typography variant="h2">Services We Provide</Typography>
              </Box>
            </Grid>
            <Grid item md={2} display={{ xs: "none", md: "flex" }} />
            <Grid
              item
              xs={12}
              md={8}
              textAlign="center"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <Typography>
                Transform your house with our comprehensive remodeling services.
                We specialize in kitchen renovations, bathroom makeovers,
                basement transformations, and whole-home remodeling. Our skilled
                team delivers exceptional craftsmanship and attention to detail,
                ensuring a seamless blend of design and functionality. Trust us
                to create your dream space and make your remodeling journey a
                rewarding experience.
              </Typography>
            </Grid>
            <Grid item>
              <Grid container rowGap={10} columnSpacing={5}>
                {services.map(service => (
                  <Grid
                    key={service.name}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    textAlign="center"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <Grid container position="relative">
                      <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        bgcolor={theme.palette.primary.dark}
                      />
                      <Box
                        component="img"
                        position="relative"
                        width="100%"
                        src={getUrl(service.id)}
                      />
                    </Grid>
                    <Box maxWidth={{ md: "70%" }} margin="auto">
                      <Typography
                        variant="h3"
                        mt={5}
                        textTransform="capitalize"
                      >
                        {service.name}
                      </Typography>
                      <Typography mt={3}>{service.description}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContentLayout>
  )
}
