import { Box, Button, Grid, Typography, useTheme } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

import { ContentLayout } from "@/components/Layout/ContentLayout"
import { getUrl } from "@/utils/cloudinary"

export const NotFound = () => {
  const theme = useTheme()

  return (
    <ContentLayout
      title="Page Not Found | MBN Contractors"
      description="The resource you were looking for was not found"
    >
      <Grid position="relative" bgcolor={theme.palette.primary.dark}>
        <ParallaxBanner style={{ height: "50vh", opacity: 0.65 }}>
          <ParallaxBannerLayer
            image={getUrl("uqo3sd")}
            expanded={false}
            speed={-50}
            startScroll={-1000}
            endScroll={1000}
            style={{ backgroundPosition: "bottom" }}
          />
        </ParallaxBanner>
      </Grid>
      <Grid
        container
        rowGap={10}
        p={10}
        textAlign="center"
        bgcolor={theme.palette.secondary.main}
      >
        <Grid item xs={12} textAlign="center" flex={1}>
          <Box>
            <Typography variant="h2">Oops... Page Not Found</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Link to="/">
            <Button variant="contained" disableElevation>
              Take Me Home
            </Button>
          </Link>
        </Grid>
      </Grid>
    </ContentLayout>
  )
}
