import { ThemeProvider } from "@mui/material"
import AOS from "aos"
import React from "react"
import { HelmetProvider } from "react-helmet-async"
import { RouterProvider } from "react-router-dom"

import "aos/dist/aos.css"
import theme from "@/assets/theme"
import { router } from "@/routes"

export const AppProvider = () => {
  React.useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      delay: 100,
      offset: 300,
    })
  }, [])

  return (
    <React.Suspense fallback={0}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </HelmetProvider>
    </React.Suspense>
  )
}
