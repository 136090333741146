import { Box, Grid, Typography, useTheme } from "@mui/material"
import React from "react"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

import { ContentLayout } from "@/components/Layout/ContentLayout"
import { getUrl } from "@/utils/cloudinary"

export const AboutUs = () => {
  const theme = useTheme()

  return (
    <ContentLayout
      title="About Us | MBN Contractors"
      description="Information on the company"
    >
      <Grid position="relative" bgcolor={theme.palette.primary.dark}>
        <ParallaxBanner style={{ height: "30vh", opacity: 0.65 }}>
          <ParallaxBannerLayer
            image={getUrl("amgsm0")}
            expanded={false}
            speed={-50}
            startScroll={-1000}
            endScroll={1000}
            style={{ backgroundPosition: "bottom" }}
          />
        </ParallaxBanner>
      </Grid>
      <Grid
        container
        rowGap={10}
        columnSpacing={10}
        p={10}
        textAlign="center"
        bgcolor={theme.palette.secondary.main}
      >
        <Grid item xs={12} textAlign="center" data-aos="fade-up">
          <Box>
            <Typography variant="h2">About Our Company</Typography>
          </Box>
        </Grid>
        <Grid item md={2} xl={3} display={{ xs: "none", md: "flex" }} />
        <Grid
          item
          xs={12}
          md={8}
          xl={6}
          textAlign="justify"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <Typography>
            <Box
              component="span"
              color={theme.palette.primary.main}
              fontWeight={500}
            >
              MBN contractors
            </Box>{" "}
            is your trusted partner in transforming houses into stunning spaces.
            With over 20 years of experience in the industry, we deliver
            exceptional remodeling solutions tailored to meet your unique needs.
            Our skilled professionals provide top-notch craftsmanship, attention
            to detail, and personalized service, ensuring your complete
            satisfaction.
          </Typography>
          <Typography mt={2}>
            At{" "}
            <Box
              component="span"
              color={theme.palette.primary.main}
              fontWeight={500}
            >
              MBN contractors
            </Box>{" "}
            , we specialize in kitchen, bathroom, and basement renovations, as
            well as whole-home remodeling. With our expertise and creativity, we
            bring your vision to life, blending modern design elements with
            functionality. From concept to completion, we collaborate closely
            with you, incorporating your ideas and preferences into a space that
            reflects your style and exceeds your expectations.
          </Typography>
          <Typography mt={2}>
            We pride ourselves on transparent communication, on-time project
            completion, and staying within your budget. Customer satisfaction is
            our priority, and our long-standing relationships with clients are a
            testament to our dedication and reliability. Explore our portfolio
            to see the transformations we have achieved, and let us turn your
            dream home into a reality. Contact us today for a consultation and
            let our experience shape your remodeling journey.
          </Typography>
        </Grid>
      </Grid>
    </ContentLayout>
  )
}
