import MenuIcon from "@mui/icons-material/Menu"
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
} from "@mui/material"
import React from "react"
import { Link, NavLink } from "react-router-dom"

import { ReactComponent as LogoSvg } from "@/assets/images/logo.svg"
import { NavigationItem } from "@/routes"

type MenuProps = {
  navigation: NavigationItem[]
  style?: { [_: string]: string | number }
}

export const MobileMenu = ({ navigation, style = {} }: MenuProps) => {
  const [toggle, setToggle] = React.useState(false)
  const toggleDrawer = () => {
    setToggle(!toggle)
  }

  return (
    <Toolbar sx={{ width: "100%" }}>
      <Grid container alignItems="center">
        <Grid item>
          <IconButton onClick={toggleDrawer} color="inherit" sx={{ p: 0 }}>
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item flex={1}>
          <Grid container justifyContent="right" alignItems="center">
            <LogoSvg
              height={40}
              width={40}
              style={{ filter: `invert(${style.invert})` }}
            />
            <Box
              ml={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography
                fontWeight={500}
                fontSize="1.5rem"
                letterSpacing=".3rem"
                lineHeight="1.2rem"
                mr="-.3rem"
              >
                MBN
              </Typography>
              <Typography fontSize=".7rem" lineHeight=".5rem" mt={0.3}>
                contractors
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Drawer open={toggle} onClose={() => setToggle(false)}>
        <List sx={{ my: 1, ml: 1, mr: 5 }}>
          <ListItem sx={{ mb: 2 }}>
            <IconButton onClick={toggleDrawer} color="inherit" sx={{ p: 0 }}>
              <MenuIcon />
            </IconButton>
          </ListItem>
          {navigation.map(nav => (
            <Link key={nav.path} to={nav.path} onClick={() => setToggle(false)}>
              <ListItem key={nav.path}>
                <Typography textAlign="center" my={0.5}>
                  {nav.name}
                </Typography>
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </Toolbar>
  )
}

export const DesktopMenu = ({ navigation, style = {} }: MenuProps) => {
  return (
    <Grid container alignItems="center" flexWrap="nowrap">
      <Grid item flex={1}>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <Grid container alignItems="center" flexWrap="nowrap">
            <LogoSvg
              height={50}
              width={50}
              style={{ filter: `invert(${style.invert})` }}
            />
            <Box
              ml={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography
                fontWeight={500}
                fontSize="1.5rem"
                letterSpacing=".3rem"
                lineHeight="1.2rem"
                mr="-.3rem"
              >
                MBN
              </Typography>
              <Typography fontSize=".7rem" lineHeight=".5rem" mt={0.3}>
                contractors
              </Typography>
            </Box>
          </Grid>
        </Link>
      </Grid>
      <Grid item>
        <Grid container columnSpacing={5} flexWrap="nowrap">
          {navigation.map(nav => (
            <Grid item key={nav.name}>
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  "&:hover > *": {
                    opacity: "1 !important",
                  },
                }}
              >
                <NavLink
                  to={nav.path}
                  style={({ isActive }) => ({
                    opacity: isActive ? 1 : 0.7,
                    transition: "opacity .25s ease-in",
                    textDecoration: "none",
                    color: "inherit",
                    fontWeight: 300,
                  })}
                >
                  {nav.name}
                </NavLink>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
