import { Email, LocationOn, Phone } from "@mui/icons-material"
import { Box, Grid, Typography, useTheme } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

import { ContactForm } from "@/components/Form/Contact"
import { ContentLayout } from "@/components/Layout/ContentLayout"
import { getUrl } from "@/utils/cloudinary"

export const Contact = () => {
  const theme = useTheme()

  return (
    <ContentLayout
      title="Contact | MBN Contractors"
      description="Company's contact information"
    >
      <Grid position="relative" bgcolor={theme.palette.primary.dark}>
        <ParallaxBanner style={{ height: "30vh", opacity: 0.65 }}>
          <ParallaxBannerLayer
            image={getUrl("vzmpdh")}
            expanded={false}
            speed={-50}
            startScroll={-1000}
            endScroll={1000}
          />
        </ParallaxBanner>
      </Grid>
      <Grid maxWidth={1280} margin="auto">
        <Grid container rowGap={10} columnSpacing={10} p={10}>
          <Grid item xs={12} textAlign="center" data-aos="fade-up">
            <Box>
              <Typography variant="h2">Let&apos;s Get In Touch</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            justifyContent="center"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <Box maxWidth={300}>
              <Typography variant="h4">Where to Find Us</Typography>
              <Box mt={5} display="flex" alignItems="center">
                <Box mr={1.5}>
                  <LocationOn color="primary" />
                </Box>
                <Box>
                  <Typography>
                    9154 Centerway Rd,
                    <br />
                    Gaithersburg, MD 20879
                  </Typography>
                </Box>
              </Box>
              <Box mt={2} display="flex" alignItems="center">
                <Box mr={1.5}>
                  <Phone color="primary" />
                </Box>
                <Box
                  sx={{
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  <Link to="tel:+1 (301) 996-3362">+1 (301) 996-3362</Link>
                </Box>
              </Box>
              <Box mt={2} display="flex" alignItems="center">
                <Box mr={1.5}>
                  <Email color="primary" />
                </Box>
                <Box
                  sx={{
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  <Link to="mailto:info@mbncontractors.com">
                    info@mbncontractors.com
                  </Link>
                </Box>
              </Box>
              <Typography mt={5}>
                Get in touch with us or fill out our convenient online form, and
                we will promptly get back to you to schedule a consultation.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            data-aos="fade-up"
            data-aos-delay={200}
            textAlign="center"
          >
            <ContactForm />
          </Grid>
        </Grid>
      </Grid>
    </ContentLayout>
  )
}
