export const getUrl = (resourceId: string): string => {
  const url = "res.cloudinary.com"
  const cloudName = "dx8ueia6n"
  const assetType = "image"
  const deliveryType = "upload"
  const transformations = ["f_auto", "w_auto", "q_50"].join("/")
  return [
    `https://${url}`,
    cloudName,
    assetType,
    deliveryType,
    transformations,
    "mbncontractors",
    resourceId,
  ].join("/")
}
