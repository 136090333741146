import { Box, Grid, Typography, useTheme } from "@mui/material"
import React from "react"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

import { ContentLayout } from "@/components/Layout/ContentLayout"
import { getUrl } from "@/utils/cloudinary"

const categories = [
  {
    name: "kitchens",
    id: "s4vg1b",
  },
  {
    name: "bathrooms",
    id: "agcqgc",
  },
  {
    name: "interiors",
    id: "wj1vhk",
  },
  {
    name: "exteriors",
    id: "h7aq0r",
  },
  {
    name: "basements",
    id: "kkhnm2",
  },
  {
    name: "sheds",
    id: "vxb6vb",
  },
] as { name: string; id: string }[]

export const Work = () => {
  const theme = useTheme()

  return (
    <ContentLayout
      title="Work Gallery | MBN Contractors"
      description="Gallery from the work we delivered"
    >
      <Grid position="relative" bgcolor={theme.palette.primary.dark}>
        <ParallaxBanner style={{ height: "30vh", opacity: 0.65 }}>
          <ParallaxBannerLayer
            image={getUrl("asrzhk")}
            expanded={false}
            speed={-50}
            startScroll={-1000}
            endScroll={1000}
          />
        </ParallaxBanner>
      </Grid>
      <Grid container bgcolor={theme.palette.secondary.main}>
        <Grid maxWidth={1280} margin="auto">
          <Grid
            container
            rowGap={10}
            columnSpacing={10}
            textAlign="center"
            sx={{ px: { xs: 5, sm: 10 }, py: 10 }}
          >
            <Grid item xs={12} textAlign="center" data-aos="fade-up">
              <Box>
                <Typography variant="h2">Our Work Gallery</Typography>
              </Box>
            </Grid>
            <Grid item md={2} display={{ xs: "none", md: "flex" }} />
            <Grid
              item
              xs={12}
              md={8}
              textAlign="center"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <Typography>
                Explore our work gallery to witness the transformations we have
                achieved for our clients over the years. From breathtaking
                kitchen renovations to luxurious bathroom makeovers and basement
                transformations, our portfolio showcases a diverse range of
                projects that demonstrate our versatility and dedication to
                excellence.
              </Typography>
            </Grid>
            <Grid item>
              <Grid container rowGap={5} columnSpacing={5}>
                {categories.map(category => (
                  <Grid
                    key={category.name}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    textAlign="center"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <Grid
                      container
                      position="relative"
                      sx={{
                        "&:hover > img": { opacity: 0.65 },
                        "&:hover > div": { opacity: 1 },
                      }}
                    >
                      <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width={"100%"}
                        height={"100%"}
                        bgcolor={theme.palette.primary.dark}
                      ></Box>
                      <Box
                        component="img"
                        position="relative"
                        width="100%"
                        src={getUrl(category.id)}
                        sx={{
                          transition: "opacity .2s ease-out",
                        }}
                      />
                      <Grid
                        container
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        alignItems="center"
                        sx={{
                          opacity: 0,
                          transition: "opacity .2s ease-out",
                        }}
                      >
                        <Box mx="auto">
                          <Typography
                            color="white"
                            fontSize={25}
                            fontWeight={300}
                          >
                            {category.name}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContentLayout>
  )
}
