import { Email, LocationOn, Phone } from "@mui/icons-material"
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

import { ContactForm } from "@/components/Form/Contact"
import { ContentLayout } from "@/components/Layout/ContentLayout"
import { services } from "@/pages/Services"
import { getUrl } from "@/utils/cloudinary"

export const Home = () => {
  const theme = useTheme()
  const mdBreakpoint = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <ContentLayout title="Home | MBN Contractors" description="Home page">
      <Grid position="relative" bgcolor={theme.palette.primary.dark}>
        <ParallaxBanner
          style={{
            height: "100vh",
            opacity: 0.65,
          }}
        >
          <ParallaxBannerLayer
            image={getUrl("jimatn")}
            expanded={false}
            speed={-50}
          />
        </ParallaxBanner>
        <Grid
          container
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          sx={{ px: [1, 8] }}
          maxWidth="xl"
          my="30px"
        >
          <Grid
            item
            display="flex"
            alignItems="flex-end"
            xs={12}
            sm={6}
            sx={{
              alignItems: { xs: "center", sm: "end" },
              mb: { xs: 0, sm: "10%" },
            }}
          >
            <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
              <Typography
                variant="h1"
                fontWeight={600}
                color="white"
                sx={{
                  fontSize: theme =>
                    theme.breakpoints.down("sm") ? "3rem" : "",
                }}
              >
                Home Remodeling & Renovations
              </Typography>

              <Link to="/contact">
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    my: { xs: 3, md: 7 },
                    display: { xs: "inline-flex", sm: "none" },
                  }}
                >
                  Get A Quote
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid maxWidth={1280} margin="auto">
          <Grid
            container
            px={{ xs: 0, sm: 5, md: 10 }}
            my={{ xs: 5, md: 10 }}
            alignItems="center"
            flexDirection={{ xs: "column-reverse", md: "row" }}
            overflow="hidden"
          >
            <Grid
              item
              xs={12}
              md={6}
              width="100%"
              height={760}
              flexBasis="auto !important"
            >
              <ParallaxBanner style={{ height: "100%" }}>
                <ParallaxBannerLayer
                  image={getUrl("x3puei")}
                  expanded={false}
                  speed={-50}
                  style={{ inset: "-10vh 0" }}
                />
              </ParallaxBanner>
            </Grid>
            <Grid item xs={12} md={6} mx="auto" data-aos="fade-up">
              <Box mx="auto" p={10}>
                <Typography variant="h2">
                  The Local Home Renovation Company of Your Choice
                </Typography>
                <Typography mt={5}>
                  Explore our gallery, schedule a consultation, and let us
                  create a home let us create a home that reflects your style
                  and becomes a space you will love.
                </Typography>
                <Link to="/about-us">
                  <Button variant="contained" sx={{ mt: 5 }} disableElevation>
                    About Us
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container bgcolor={theme.palette.secondary.main}>
        <Grid maxWidth={1280} margin="auto">
          <Grid
            container
            rowGap={10}
            columnSpacing={10}
            p={10}
            textAlign="center"
          >
            <Grid item xs={12} textAlign="center" data-aos="fade-up">
              <Box>
                <Typography variant="h2">What We Offer</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <Box maxWidth="250px" mx="auto">
                <Typography variant="h3">20+ years of experience</Typography>
                <Typography mt={5}>
                  We bring over two decades worth of expertise and knowledge to
                  every project we undertake.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              data-aos="fade-up"
              data-aos-delay={mdBreakpoint ? 100 : 200}
            >
              <Box maxWidth="250px" mx="auto">
                <Typography variant="h3">Financing & planning</Typography>
                <Typography mt={5}>
                  We combine our expertise with flexible and easy financing
                  options, making your dream home renovation a seamless and
                  affordable process.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              data-aos="fade-up"
              data-aos-delay={mdBreakpoint ? 100 : 300}
            >
              <Box maxWidth="250px" mx="auto">
                <Typography variant="h3">Home Consultations</Typography>
                <Typography mt={5}>
                  We offer personalized home consultations to understand your
                  vision, discuss your goals, and provide expert guidance
                  throughout the remodeling journey.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              data-aos="fade-up"
              data-aos-delay={mdBreakpoint ? 100 : 400}
            >
              <Box maxWidth="250px" mx="auto">
                <Typography variant="h3">Licensed & Insured</Typography>
                <Typography mt={5}>
                  You can trust us with the confidence and assurance that your
                  project will be handled with the utmost professionalism and
                  protection.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid maxWidth={1280} margin="auto">
          <Grid
            container
            rowGap={10}
            columnSpacing={10}
            textAlign="center"
            sx={{ px: { xs: 5, sm: 10 }, py: 10 }}
          >
            <Grid item xs={12} textAlign="center" data-aos="fade-up">
              <Box>
                <Typography variant="h2">Our Services</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container rowGap={5} columnSpacing={5}>
                {services.map(service => (
                  <Grid
                    key={service.name}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    textAlign="center"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <Grid
                      container
                      position="relative"
                      maxWidth={680}
                      sx={{
                        "&:hover > img": { opacity: 0.65 },
                        "&:hover > div": { opacity: 1 },
                      }}
                    >
                      <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width={"100%"}
                        height={"100%"}
                        bgcolor={theme.palette.primary.dark}
                      />
                      <Box
                        component="img"
                        position="relative"
                        width="100%"
                        src={getUrl(service.id)}
                        sx={{
                          transition: "opacity .2s ease-out",
                        }}
                      />
                      <Grid
                        container
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        alignItems="center"
                        sx={{
                          opacity: 0,
                          transition: "opacity .2s ease-out",
                        }}
                      >
                        <Box mx="auto">
                          <Typography
                            color="white"
                            fontSize={25}
                            fontWeight={300}
                          >
                            {service.name}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container bgcolor={theme.palette.secondary.main}>
        <Grid maxWidth={1280} margin="auto">
          <Grid container rowGap={10} columnSpacing={10} p={10}>
            <Grid item xs={12} textAlign="center" data-aos="fade-up">
              <Box>
                <Typography variant="h2">Let&apos;s Work Together</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              justifyContent="center"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <Box maxWidth={300}>
                <Typography variant="h4">Where to Find Us</Typography>
                <Box mt={5} display="flex" alignItems="center">
                  <Box mr={1.5}>
                    <LocationOn color="primary" />
                  </Box>
                  <Box>
                    <Typography>
                      9154 Centerway Rd,
                      <br />
                      Gaithersburg, MD 20879
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                  <Box mr={1.5}>
                    <Phone color="primary" />
                  </Box>
                  <Box
                    sx={{
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    <Link to="tel:+1 (301) 996-3362">+1 (301) 996-3362</Link>
                  </Box>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                  <Box mr={1.5}>
                    <Email color="primary" />
                  </Box>
                  <Box
                    sx={{
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    <Link to="mailto:info@mbncontractors.com">
                      info@mbncontractors.com
                    </Link>
                  </Box>
                </Box>
                <Typography mt={5}>
                  Give us a call or fill out the form to set up a home
                  consultation.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              data-aos="fade-up"
              data-aos-delay={200}
              textAlign="center"
            >
              <ContactForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContentLayout>
  )
}
