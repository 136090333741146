import React from "react"
import {
  Navigate,
  Outlet,
  createBrowserRouter,
  ScrollRestoration,
} from "react-router-dom"

import { BaseLayout } from "@/components/Layout/BaseLayout"
import { NotFound } from "@/pages/404"
import { AboutUs } from "@/pages/AboutUs"
import { Contact } from "@/pages/Contact"
import { Home } from "@/pages/Home"
import { Services } from "@/pages/Services"
import { Work } from "@/pages/Work"

export type NavigationItem = {
  name: string
  path: string
}

export const NavigationItems = [
  { name: "Home", path: "/" },
  { name: "Services", path: "/services" },
  { name: "Work", path: "/work" },
  { name: "About Us", path: "/about-us" },
  { name: "Contact", path: "/contact" },
] as NavigationItem[]

const App = () => {
  return (
    <BaseLayout>
      <Outlet />
      <ScrollRestoration />
    </BaseLayout>
  )
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <Home /> },
      { path: "/home", element: <Navigate to="/" /> },
      { path: "/services", element: <Services /> },
      { path: "/work", element: <Work /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/contact", element: <Contact /> },
      { path: "*", element: <NotFound /> },
    ],
  },
])
