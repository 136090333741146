import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import {
  useForm,
  FieldValues,
  FormProvider,
  UseFormReturn,
} from "react-hook-form"
import { z, ZodSchema } from "zod"

export const Form = <T extends FieldValues>({
  children,
  schema,
}: {
  children: (methods: UseFormReturn<T>) => React.ReactNode
  schema: ZodSchema
}) => {
  type SchemaT = z.infer<typeof schema>
  const methods = useForm<SchemaT>({ resolver: zodResolver(schema) })
  return <FormProvider {...methods}>{children(methods)}</FormProvider>
}
