import { createTheme, responsiveFontSizes } from "@mui/material"

let theme = createTheme({
  palette: {
    primary: {
      main: "#f57c00",
      light: "#f8f6f4",
      dark: "#303030",
      contrastText: "#f8f6f4",
    },
    secondary: {
      main: "rgba(219, 219, 219, .25)",
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
    fontWeightLight: 100,
    fontWeightRegular: 200,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    color: "#303030",
    h2: {
      fontSize: "2.5rem",
      fontWeight: 600,
    },
    h3: {
      color: "#f57c00",
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: 1.4,
    },
    button: {
      fontWeight: 600,
      color: "#F8F6F4",
    },
  },
})

theme = responsiveFontSizes(theme)

export default theme
