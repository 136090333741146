import CloseIcon from "@mui/icons-material/Close"
import { Alert as MuiAlert, IconButton, Snackbar } from "@mui/material"
import React from "react"

import { AlertProps, AlertContext } from "@/components/Alert/types"

export const Alert = ({
  ctx,
  onClose = () => {
    ctx.setProps({ ...ctx.props, open: false })
  },
}: AlertProps & {
  ctx: AlertContext
  onClose?: (e: Event | React.SyntheticEvent<any, Event>) => void
}) => {
  return (
    <Snackbar open={ctx.props.open} autoHideDuration={5000} onClose={onClose}>
      <MuiAlert
        severity={ctx.props.severity}
        action={
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {ctx.props.message}
      </MuiAlert>
    </Snackbar>
  )
}
