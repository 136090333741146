import React from "react"

import { Head } from "@/components/Head"

export const ContentLayout = ({
  title,
  description,
  children,
}: {
  title?: string
  description?: string
  children: React.ReactNode
}) => {
  return (
    <>
      <Head title={title} description={description} />
      {children}
    </>
  )
}
