import emailjs from "@emailjs/browser"
import { Button, Grid, TextField } from "@mui/material"
import React from "react"
import { SubmitHandler } from "react-hook-form"
import { z } from "zod"

import { AlertContext } from "@/components/Alert/types"
import { Form } from "@/components/Form"
import { alertContext } from "@/components/Layout/BaseLayout"

const Schema = z.object({
  firstName: z.string().min(1, "First Name is required").max(50),
  lastName: z.string().min(1, "Last Name is required").max(50),
  email: z.string().min(1, "Email is required").email("Invalid email"),
  subject: z.string().min(1, "Subject required").max(200),
  message: z.string().min(1, "Message is required").max(500),
})

export const ContactForm = () => {
  type SchemaT = z.infer<typeof Schema>
  const alert = React.useContext<AlertContext>(alertContext)
  const sendEmail = (data: SchemaT): Promise<void> => {
    return new Promise<void>(resolve => {
      emailjs
        .send("service_oqctwaa", "template_l1l6yki", data, "WtCheXKLerzolc4Xg")
        .then(() => {
          alert.setProps({
            open: true,
            severity: "success",
            message:
              "Thank you for your message, we will get back to you as soon as " +
              "possible.",
          })
          resolve()
        })
        .catch(() => {
          alert.setProps({
            open: true,
            severity: "error",
            message:
              "Unable to send email at the moment. " +
              "Please use other means to get in contact with us.",
          })
        })
    })
  }

  return (
    <Form<SchemaT> schema={Schema}>
      {({ register, formState, handleSubmit, reset }) => {
        const onSubmit: SubmitHandler<SchemaT> = data =>
          sendEmail(data).then(() => reset())
        return (
          <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
            <Grid container columnSpacing={5} rowSpacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="First Name"
                  size="small"
                  fullWidth={true}
                  autoComplete="off"
                  variant="standard"
                  error={!!formState.errors.firstName}
                  helperText={formState.errors.firstName?.message}
                  {...register("firstName")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="Last Name"
                  size="small"
                  fullWidth={true}
                  autoComplete="off"
                  variant="standard"
                  error={!!formState.errors.lastName}
                  helperText={formState.errors.lastName?.message}
                  {...register("lastName")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Email"
                  size="small"
                  fullWidth={true}
                  autoComplete="off"
                  variant="standard"
                  error={!!formState.errors.email}
                  helperText={formState.errors.email?.message}
                  {...register("email")}
                >
                  We will never share your email.
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Subject"
                  size="small"
                  fullWidth={true}
                  autoComplete="off"
                  variant="standard"
                  error={!!formState.errors.subject}
                  helperText={formState.errors.subject?.message}
                  {...register("subject")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Message"
                  size="small"
                  fullWidth={true}
                  multiline={true}
                  rows={4}
                  autoComplete="off"
                  variant="standard"
                  error={!!formState.errors.message}
                  helperText={formState.errors.message?.message}
                  {...register("message")}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  fullWidth={true}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      }}
    </Form>
  )
}
