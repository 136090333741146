import { Box, Container, useTheme } from "@mui/material"
import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"

import { Alert } from "@/components/Alert"
import { AlertProps, AlertContext } from "@/components/Alert/types"
import { Footer } from "@/components/Footer"
import { Navbar } from "@/components/Navbar"
import { NavigationItems } from "@/routes"

export const alertContext = React.createContext<AlertContext>({
  props: {},
  setProps: () => {},
})

export const BaseLayout = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme()
  const [alert, setAlert] = React.useState<AlertProps>({})
  const ctx = { props: alert, setProps: setAlert }

  return (
    <>
      <Box bgcolor={theme.palette.primary.light} overflow="hidden">
        <alertContext.Provider value={{ ...ctx }}>
          <Navbar threshold={100} navigation={NavigationItems} />
          <Container maxWidth={false} disableGutters>
            <Alert {...{ ctx: ctx }} />
            <ParallaxProvider>
              <main>{children}</main>
            </ParallaxProvider>
          </Container>
          <Footer navigation={NavigationItems} />
        </alertContext.Provider>
      </Box>
    </>
  )
}
